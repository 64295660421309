import React from 'react'
import Layout from '../../components/layout'
import StrongBoxTable from "../../components/StrongBoxTable"
import TransactionDetails from "../../components/TransactionDetails"
import Footer from '../../components/Footer'

class StrongBoxPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isArticleVisible: false,
      timeout: false,
      articleTimeout: false,
      article: '',
      loading: 'is-loading',
      trId:null,
      transactionDetails:null
    }
    this.handleOpenArticle = this.handleOpenArticle.bind(this)
    this.handleCloseArticle = this.handleCloseArticle.bind(this)
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount () {
    this.timeoutId = setTimeout(() => {
        this.setState({loading: ''});
    }, 100);
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount () {
    if (this.timeoutId) {
        clearTimeout(this.timeoutId);
    }
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleOpenArticle(article,transactionToken) {

    this.setState({
      isArticleVisible: !this.state.isArticleVisible,
      article: article,
      trId:transactionToken
    })
    let trhash = this.props.location.hash
    let requestUrl = `${process.env.GATSBY_TRUSTA_API_URL}/get-strongbox-transaction?accessTok=${trhash.substr(1)}&token=${transactionToken}`
    console.log(requestUrl)
    fetch(requestUrl, {
      method: 'GET', 
      mode: 'cors', // no-cors, *cors, same-origin
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      return response.json();
    }).then((data) => {
      console.log(data)
      if (data.status === 'success'){
        this.setState({transactionDetails:data.transaction})
      }
    });
    setTimeout(() => {
      this.setState({
        timeout: !this.state.timeout
      })
    }, 325)

    setTimeout(() => {
      this.setState({
        articleTimeout: !this.state.articleTimeout
      })
    }, 350)

  }

  handleCloseArticle() {

    this.setState({
      articleTimeout: !this.state.articleTimeout,
    })

    setTimeout(() => {
      this.setState({
        timeout: !this.state.timeout
      })
    }, 325)

    setTimeout(() => {
      this.setState({
        isArticleVisible: !this.state.isArticleVisible,
        article: '',
        transactionDetails: null
      })
    }, 350)

  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      if (this.state.isArticleVisible) {
        this.handleCloseArticle();
      }
    }
  }
  
  render() {
    return (
      <Layout location={this.props.location}>
        <div className={`body ${this.state.loading} ${this.state.isArticleVisible ? 'is-article-visible' : ''}`}>
          <div id="wrapper">
            
          <TransactionDetails
              isArticleVisible={this.state.isArticleVisible}
              timeout={this.state.timeout}
              articleTimeout={this.state.articleTimeout}
              article={this.state.article}
              onCloseArticle={this.handleCloseArticle}
              setWrapperRef={this.setWrapperRef}
              trToken = {this.state.trId}
              transactionDetails = {this.state.transactionDetails}

          />

            <StrongBoxTable onOpenArticle={this.handleOpenArticle} timeout={this.state.timeout} path={this.props.location}/>
            <div>
              <a className="button" style={{marginRight:"0.5rem"}}href={`https://t.me/${process.env.GATSBY_TELEGRAM_BOT}`}>Повернутись</a>
            </div>
            <Footer timeout={this.state.timeout} />
          </div>
          <div id="bg"></div>
        </div>
      </Layout>
    )
  }
}

export default StrongBoxPage
