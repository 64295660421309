import PropTypes from 'prop-types'
import React from 'react'

class TransactionDetails extends React.Component {
  constructor(props){
    super(props)
    
  }

  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="transaction"
          className={`${this.props.article === 'transaction' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none',textAlign: "left" }}
        >
          <h2 className="major">Деталі завдатку</h2>
          <span className="image main">
          </span>
          {this.props.transactionDetails ?(
            <>
            <p style={{ margin: "0 0 0.25rem" }}>
              <strong> Рахунок {this.props.transactionDetails.human_id} </strong> 
            </p>
            <p style={{ margin: "0 0 0.25rem" }}>
              <strong>
                Отримувач:&nbsp;{this.props.transactionDetails.buyer || 'не вказано'}
              </strong>
            </p>
            <p style={{ margin: "0 0 0.25rem" }}>
              <strong>
                Завдаток за:&nbsp; {this.props.transactionDetails.name || 'не вказано' }
              </strong>
            </p>
            <p style={{ margin: "0 0 0.25rem" }}>
              <strong>
                Сума завдатку:&nbsp; {this.props.transactionDetails.price || 'не вказано' }  {this.props.transactionDetails.currency } 
              </strong>
            </p>
            <p style={{ margin: "0 0 0.25rem" }}>
              <strong>
                Завдаток для:&nbsp; {this.props.transactionDetails.seller_show_name || 'не вказано' }
              </strong>
            </p>

          { this.props.transactionDetails.transaction_type === 'sent' ? (
            <>
            { this.props.transactionDetails?.payment_info ? (
              <p style={{ margin: "0 0 0.25rem" }}>
                 <strong>
                  Завдаток на карту:&nbsp;  {this.props.transactionDetails.payment_info.pan}
                 </strong>
                
              </p>
              ):(null)
            }
            </>
          ):(
            <>
            { this.props.transactionDetails?.payment_info ? (
              <p style={{ margin: "0 0 0.25rem" }}>
                <strong>
                  Сплачено з карти:&nbsp; {this.props.transactionDetails.payment_info.pan}
                </strong>
                
              </p>
              ):(null)
            }
            </>
          )}
          
          <p style={{ margin: "0 0 0.25rem" }}>
            <strong>
              Email постачальника: &nbsp; {this.props.transactionDetails.seller_email || 'не вказано' }
            </strong>
          </p>
          <p style={{ margin: "0 0 0.25rem" }}>
            <strong>
              Tелефон постачальника: &nbsp; {this.props.transactionDetails.seller_phone || 'не вказано' }
            </strong>
          </p>
          <p style={{ margin: "0 0 0.25rem" }}>
            <strong>
              Статус: 
              {/* { item.status < 5 ?(<>Чорнетка</>):(null)}
                        { item.status >= 5 && item.status < 38 ?(<>{item.seller_id === this.state.userId ?('Надісланий'):('Отриманий')}</>):(null)}
                        {item.status === 38 ? (<>Оплачений</>):(null) }
                        {item.status === 65 ? (<>Запит на скасування</>):(null) }
                        {item.status === 129 ? (<>{item.seller_id === this.state.userId ?('Підтверджено'):('Запит на підтвердження')}</>):(null) }
                        {item.status === 130 ? (<>{item.seller_id === this.state.userId ?('Запит на підтвердження'):('Підтверджено')}</>):(null) }
                        {item.status === 252 ? (<>Спір</>):(null)}
                        {item.status > 252 && item.status < 1025  ? (<>Завершення</>):(null)}
                        {item.status >=1025 && item.status < 2049 ?(<>Завершений</>):(null)}
                        {item.status >=2049  ?(<>Видалений </>):(null)}
                         */}

              {this.props.transactionDetails?.status_val < 5 ? (<span style={{marginLeft:"0.5rem"}}>Чорнетка</span>):(null)}
              {this.props.transactionDetails?.status_val >=5 && this.props.transactionDetails?.status_val < 34 ?(<span style={{marginLeft:"0.5rem"}}>
              {this.props.transactionDetails?.seller_id ===  this.props.transactionDetails?.requestor_user_id ? 'Надісланий':'Отриманий'}</span>):(null)}
              {this.props.transactionDetails?.status_val === 34 ? (<span style={{marginLeft:"0.5rem"}}>Розпочато оплату</span>):(null) }
              {this.props.transactionDetails?.status_val === 38 ? (<span style={{marginLeft:"0.5rem"}}>Оплачений</span>):(null) }
              {this.props.transactionDetails?.status_val === 65 ? (<span style={{marginLeft:"0.5rem"}}>Запит на скасування</span>):(null) }
              {this.props.transactionDetails?.status_val === 129 ? (<span style={{marginLeft:"0.5rem"}}>{this.props.transactionDetails?.seller_id ===  this.props.transactionDetails?.requestor_user_id ?('Підтверджено'):('Запит на підтвердження')}</span>):(null) }
              {this.props.transactionDetails?.status_val === 130 ? (<span style={{marginLeft:"0.5rem"}}>{this.props.transactionDetails?.seller_id ===  this.props.transactionDetails?.requestor_user_id ?('Запит на підтвердження'):('Підтверджено')}</span>):(null) }
              {this.props.transactionDetails?.status_val === 252 ? (<span style={{marginLeft:"0.5rem"}}>Спір</span>):(null)}
              {this.props.transactionDetails?.status_val > 252 && this.props.transactionDetails?.status_val < 1025  ? (<span style={{marginLeft:"0.5rem"}}>Завершення</span>):(null)}
              {this.props.transactionDetails?.status_val >=1025 && this.props.transactionDetails?.status_val < 2049 ?(<span style={{marginLeft:"0.5rem"}}>Завершений</span>):(null)}
              {this.props.transactionDetails?.status_val >=2049  ? (<span style={{marginLeft:"0.5rem"}}>Видалений </span>):(null)}
            </strong>
          </p>
          {this.props.transactionDetails?.status_val &&  this.props.transactionDetails?.status_val < 513 ? (
            <a className="button" style={{marginRight:"0.5rem"}}href={`https://t.me/${process.env.GATSBY_TELEGRAM_BOT}?start=lookup_${this.props.transactionDetails?.token}`}>Керувати</a>
          ):(
            null
          )}
            </>
          ):(null)}
          <a className="button" style={{marginLeft:"0.5rem"}} onClick={() => { this.props.onCloseArticle() }} >Назад</a>
          {close}
        </article>
      </div>
    )
  }
}

TransactionDetails.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired
}

export default TransactionDetails
