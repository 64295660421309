import React, { Component } from "react"
import PropTypes from 'prop-types'
const PAGE_SIZE = 24

class StrongBoxTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
        transactions:[],
        isLoading: true,
        error: false,
        page: 0,
        ownerId:0,
    }
    this.changePagePrev = this.changePagePrev.bind(this)
    this.changePageNext = this.changePageNext.bind(this)
  }

  changePagePrev(event){
    event.preventDefault()
    const page = this.state.page
    if(this.state.page > 0){
      this.setState({
          page: this.state.page - 1,
          transactions:[],
          isLoading: true
      })
      
      let trhash = this.props.path.hash
      let requestUrl = `${process.env.GATSBY_TRUSTA_API_URL}/get-strongbox-transactions?accessTok=${trhash.substr(1)}&page=${page - 1 }`
      fetch(requestUrl, {
        method: 'GET', 
        mode: 'cors', // no-cors, *cors, same-origin
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        return response.json();
      }).then((data) => {
        console.log(data)
        if (data.status === 'success'){
          this.setState({transactions:data.transactions,ownerId:data.userId,isLoading:false})
        }
        else{
            this.setState({
              error: data.message
            })
        }
      });
    }
    else{
      this.setState({prevButton:false})
    }
  }
  changePageNext(event){
    event.preventDefault()
      const page = this.state.page
      this.setState({
          page: this.state.page + 1,
          transactions:[],
          isLoading: true
      })
      let trhash = this.props.path.hash
      let requestUrl = `${process.env.GATSBY_TRUSTA_API_URL}/get-strongbox-transactions?accessTok=${trhash.substr(1)}&page=${page +1}`
      fetch(requestUrl, {
        method: 'GET', 
        mode: 'cors', // no-cors, *cors, same-origin
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        return response.json();
      }).then((data) => {
        console.log(data)
        if (data.status === 'success'){
          this.setState({transactions:data.transactions,ownerId:data.userId,isLoading:false})
        }
        else{
            this.setState({
              error: data.message
            })
        }
      });
  }

  
  componentDidMount(){
    console.log('Start')
    let trhash = this.props.path.hash
    console.log(trhash)
    let requestUrl = `${process.env.GATSBY_TRUSTA_API_URL}/get-strongbox-transactions?accessTok=${trhash.substr(1)}&page=${this.state.page}`
    fetch(requestUrl, {
      method: 'GET', 
      mode: 'cors', // no-cors, *cors, same-origin
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      return response.json();
    }).then((data) => {
      console.log(data)
      if (data.status === 'success'){
        this.setState({transactions:data.transactions,ownerId:data.userId,isLoading:false})
      }
      else{
          this.setState({
            error: data.message
          })
      }
    });
  }

  render(){
    return(
      <>
      {this.state.error ? 
      (
        <div className="inner" style={ this.state.error ? {display:"block"}: {display:"none"} }>       
          <p className="error">
            <h3> <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Помилка</h3>
          </p>
          <p className="error">
            <h5>{this.state.error }</h5>
          </p>
        </div>
      ) : (
      
        <div className={`table-wrapper`} style={this.props.timeout ? { display: 'none' } : {} }>
          <h3 style={{"padding":"2.5rem","textAlign":"center" }}> Мій сейф </h3>
          
          {this.state.transactions.length > 0 ?(
              <table>
              <thead>
                  <tr>
                   <th>Дата</th>
                   <th>Ціль завдатку</th>
                   <th>Статус</th>
                   <th>Сума</th>
                   <th></th>
                  </tr>
              </thead>
              <tbody>
                 { this.state.transactions.map((item,index) => {
                   return (
                    <tr key={index}>
                      <td> <small> {item.creation_date.replace(/\.\d+/,'')} </small></td>
                      <td> <small>{item.name}</small> </td>
                      <td> <small>
                      { item.status < 5 ?(<>Чорнетка</>):(null)}
                        { item.status >= 5 && item.status < 34 ?(<>{item.seller_id === this.state.ownerId ?('Надісланий'):('Отриманий')}</>):(null)}
                        {item.status === 34 ? (<>Розпочато оплату</>):(null) }
                        {item.status === 38 ? (<>Оплачений</>):(null) }
                        {item.status === 65 ? (<>Запит на скасування</>):(null) }
                        {item.status === 129 ? (<>{item.seller_id === this.state.ownerId ?('Підтверджено'):('Запит на підтвердження')}</>):(null) }
                        {item.status === 130 ? (<>{item.seller_id === this.state.ownerId ?('Запит на підтвердження'):('Підтверджено')}</>):(null) }
                        {item.status === 252 ? (<>Спір</>):(null)}
                        {item.status > 252 && item.status < 1025  ? (<>Завершення</>):(null)}
                        {item.status >=1025 && item.status < 2049 ?(<>Завершений</>):(null)}
                        {item.status >=2049  ?(<>Видалений </>):(null)}
                        </small></td>
                      <td> <small>{item.price } {item.currency }</small></td>
                      <td style={{"verticalAlign":"middle"}}> <a style={{borderBottom:"none","cursor":"pointer"}} onClick={() => {this.props.onOpenArticle('transaction',item.token)}}><i class="fa fa-eye" aria-hidden="true"></i></a> </td>
                    </tr>
                   )
                 })
                }
              </tbody>
              </table>
              
          ):(
            <div> 
              {this.state.isLoading ? 
                (<span>Завантажуємо ...</span>):(<span>Нажаль список транзакцій пустий</span>)
              }
            </div>
          )}
          {this.state.transactions[0]?.total_count > PAGE_SIZE ? (
              <div style={{textAlign:"center"}}>
                {this.state.page ? (
                    <button onClick={this.changePagePrev}> <i class="fa fa-chevron-left"></i> </button>
                ):(
                  <button disabled="disabled"> <i class="fa fa-chevron-left"></i> </button>
                )
                }
                
                <button disabled="disabled" style={{"boxShadow":"none","border":"0","overflow":"hidden","opacity":"0.75"}}>Page {this.state.page + 1 } from  {Math.ceil(this.state.transactions[0]?.total_count/PAGE_SIZE)} </button>

                { Math.ceil(this.state.transactions[0]?.total_count/PAGE_SIZE) > (this.state.page + 1) ? (
                    <button onClick={this.changePageNext} ><i class="fa fa-chevron-right"></i> </button>
                ):
                (
                  <button disabled="disabled" ><i class="fa fa-chevron-right"></i> </button>
                )
                }
                
              </div>
          ):(null)
          }
        </div> 
      )
      
      }
      
       
       </>
    )
  }
  
}
StrongBoxTable.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}
export default StrongBoxTable